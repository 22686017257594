/* Base button styling */

.btn {
    display: inline-flex;
    height: 5rem;
    font-size: 1.4rem;
    line-height: 1;
    font-weight: 600;
    letter-spacing: .05em;
    border: 1.5px solid $nda-blue;
    color: $nda-blue;
    padding: 0 2.5rem;
    margin: 1em auto;
    border-radius: 2px;
    text-decoration: none;
    transition: all 0.3s ease;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    &:hover, &:focus {
        background-color: $nda-blue;
        color: white;
        text-decoration: none;
    }
    &.white-outline {
        background: transparent;
        color: white;
        border-color: white;
        &:hover {
            background-color: white;
            color: $nda-blue !important;
        }
    }
    &.white {
        background-color: white;
        border-color: white !important;
        color: $nda-blue !important;
        font-weight: 600;
        &:hover {
            background-color: $nda-blue-lt;
            border-color: $nda-blue-lt !important;
        }
    }
    &.blue {
        background-color: $nda-blue;
        border-color: $nda-blue !important;
        color: white !important;
        font-weight: 600;
        &:hover {
            background-color: white;
            color: $nda-blue !important;
        }
    }
    &.danger {
        background-color: white;
        border-color: $stoplight-red !important;
        color: $stoplight-red !important;
        font-weight: 600;
        &:hover {
            background-color: $stoplight-light-red;
        }
    }
    &.grey {
        background-color: $light-grey;
        border-color: $light-grey !important;
        color: $dark-grey;
        font-weight: 600;
        &:hover {
            background-color: $light-grey;
            border-color: $nda-blue !important;
            color: $nda-blue !important;
        }
        &.active {
            color: white;
            background-color: $nda-blue;
            border-color: $nda-blue !important;
            &:hover {
                background-color: $light-grey;
            }

        }
    }
    &:disabled {
        background: $light-grey;
        border-color: $light-grey !important;
        color: $medium-grey !important;
        &:hover {
            cursor: default;
            background: $light-grey;
            color: $medium-grey !important;
        }
    }
    &.client-sign {
        background-color: $white;
        background-image: url(../nda-status/status_signature.svg);
        background-size: 3rem 3rem;
        background-repeat: no-repeat;
        background-position: left center;
        border-radius: 8px !important;
        color: $nda-blue;
        border-color: $nda-blue !important;
        font-weight: 600;
        padding-left: 3.5rem !important; // Adjust this value as needed
        &:hover {
            background-color: $nda-blue;
            color: $white !important;
        }
    }
}
