/* TODO: If parent imports are correct, we don't need to import colors again */
@import "_colors";

@mixin authBox {
  .auth-box {
    background-color: white;
    padding: 5rem;
    box-shadow: 0px 2px 15px #d7d7d7;
    margin-left: auto;
    margin-right: auto;
    h1, .h1 {
      font-size: 30px;
    }
    form {
      ::placeholder {
        font-size: 1em;
      }
      .display-input {
        input, select, textarea {
          font-size: 14px;
        }
        .form-group {
          label {
            font-size: 12px;
          }
        }
        .helper-text {
          font-size: 10px;
        }
      } 
    }
    @include respond-to-max('laptop') {
      max-width: 90%;
    }
  }
}

@mixin flexWidth($widths...) {
  @each $width in $widths {
      &.flex-#{round($width)} {
          // flex-basis: #{percentage($width/100)};
          flex-basis: #{percentage(calc($width/100))};
          @include respond-to-max('tablet') {
            flex-basis: 100%;
            padding-right: 0;
          }
      }
  }
}

@mixin dropDown {
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image:
          linear-gradient(45deg, transparent 50%, gray 50%),
          linear-gradient(135deg, gray 50%, transparent 50%);
        background-position:
          calc(100% - 15px) calc(1em + 2px),
          calc(100% - 10px) calc(1em + 2px);
        background-size:
          5px 5px,
          5px 5px;
        background-repeat: no-repeat;
      }
}

@mixin shadow {
    box-shadow: $ex-light-grey 1px 1px 15px;
}

@mixin lineClamp($lines: 2) {
  line-clamp: $lines;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// TODO: This is shared by the outbound campaign screen and in the app, need to break down so both can use
@mixin tempOutbound {
  .box {
    background: white;
    padding: 8rem 12rem;
    border-radius: 4px;
    box-shadow: 0px 0px 10px rgba(49, 51, 86, .1);
    @include respond-to-max('laptop') {
        padding: 2rem;
        h1 {
            text-align: center;
        }
    }
    .instruction-steps {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin: 4rem 0;
        @include respond-to-max('laptop') {
            flex-wrap: wrap;
        }
        &.as-btn {
            justify-content: center;
            .step {
                margin: 0 1rem 1rem 1rem;
                border: 1px solid transparent;
                &:hover {
                    cursor: pointer;
                    border: 1px solid $nda-blue;
                }
            }
        }
        .step {
            border-radius: 2px;
            display: flex;
            flex-direction: column;
            align-items: center;
            background: #EEF5FD;
            padding: 4rem 2rem;
            min-width: 32%;
            @include respond-to-max('laptop') {
                min-width: 100%;
                flex-basis: 100%;
                margin-bottom: 1rem;
            }
            img {
                margin-bottom: 2rem;
            }
            .step-num {
                color: #79AEEE;
                font-weight: 700;
            }
            .step-title {
                padding-top: .5rem;;
                color: $nda-blue;
                font-weight: 700;
                font-size: 1.6rem;
                text-align: center;
            }
        }
    }
    .vertical-steps {
        display: flex;
        flex-direction: column;
        .step {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            border-top: 3px solid #EEF5FD;
            padding-top: 3rem;
            margin-bottom: 3rem;
            &:first-child{
                border-top: none;
                padding-top: 0;
            }
            .step-icon {
                width: 75px;
                @include respond-to-max('laptop') {
                    display: none;
                }
            }
            .step-title-block {
                flex-basis: calc(50% - (75px + 3rem));
                margin-right: 3rem;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                .step-num {
                    color: #79AEEE;
                    font-weight: 700;
                    font-size: 1.5rem;
                }
                .step-title {
                    color: $nda-blue;
                    font-weight: 700;
                    font-size: 3rem;
                    line-height: 1.2;
                }
            }
            .step-content {
                flex-basis: 50%;
            }
        }
        .outside-step-content {
            padding-left: 75px;
            @include respond-to-max('laptop') {
                padding-left: 0;
            }
        }
    }
  }
}