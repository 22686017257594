/* All shared resources */

/* required for bootstrap */
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

/* optional bootstrap */
@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/forms";
@import "../../../node_modules/bootstrap/scss/input-group";
@import "../../../node_modules/bootstrap/scss/nav";
@import "../../../node_modules/bootstrap/scss/navbar";
@import "../../../node_modules/bootstrap/scss/card";
@import "../../../node_modules/bootstrap/scss/alert";
@import "../../../node_modules/bootstrap/scss/modal";
@import "../../../node_modules/bootstrap/scss/popover";
@import "../../../node_modules/bootstrap/scss/tooltip";
@import "../../../node_modules/bootstrap/scss/utilities";
@import "../../../node_modules/bootstrap/scss/tables";

@import "_colors";
@import "_mixins";

/* Eventually these will start to replace the boostrap libs... */
@import "_breakpoints";
@import "_text";
@import "_buttons";
@import "_layout";
@import "_forms";

/* Shared Navigation */

@import "_navigation-shared";


/* Items shared by app and marketing */
#root {
    overflow: hidden;
}

#content {
    min-height: calc(100vh - 100px);
}
@include authBox;

/* Used For Screen Readers */
.visually-hidden {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal !important;
}

.clickable {
    color: $nda-blue;
    text-decoration: underline;

    &:hover {
        cursor: pointer;
        text-decoration: none;
    }

    &.white {
        color: white;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}