 @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600&display=swap');

/* Base text styling */
body, html {
    font-weight: 200;
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
}

h1, h2, h3, h4, .h1, .h2, .h3, .h4 {
    color: $nda-blue;
}

h1, .h1 {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1em;
  font-weight: 300;
}

h2, .h2 {
  font-size: 2.5rem;
  line-height: 1.21875;
  &.engagement-title {
    font-weight: 200;
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: 2rem;
  line-height: 1.25;
}

h4, .h4 {
  font-size: 1.5rem;
}

p {
  font-size: 1.4rem;
  &.feature {
    font-size: 1.25rem;
  }
}

b, strong {
    font-weight: 800;
}

.error-notice {
  color: red;
}

@include respond-to-max('laptop') {
  h1, .h1 {
    font-size: 2.15rem;
  }
}

@include respond-to-max('tablet') {
  h1, .h1 {
    text-align: center;
  }

  p {
    font-size: 1.1rem;
  }

  // &.feature{
  //   text-align: center;
  // }
}
