.shared-nav {
    font-weight: 300;
    background: rgba(205, 220, 237, 1);
    background: linear-gradient(90deg, rgba(245, 248, 251, 1) 0%, rgba(205, 220, 237, 1) 100%);
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    .logo {
        width: auto;
        height: 25px;
    }
    .navbar-toggle {
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 1002;
        cursor: pointer;
        width: 40px;
        height: 32px;
        &.bars {
            position: relative;
            .top, .middle, .bottom {
                position: absolute;
                width: 40px;
                height: 4px;
                background-color: $nda-blue;
                border-radius: 2px;
                transition: all 0.3s ease;
                left: 0;
            }
            .top {
                top: 0;
            }
            .middle {
                top: calc(50% - 2px);
                margin-left: 4px;
            }
            .bottom {
                bottom: 0;
            }
        }
    }
    a {
        .app-label {
            display: none;
        }
        &.active {
            border-bottom: 2px solid $nda-blue;
            // i, svg, span {
            //     border-bottom: 2px solid $nda-blue;
            // }
        }
        i, svg {
            color: white;
            // padding-bottom: 5px;
            border-bottom: 2px solid transparent;
            &:hover {
                color: $nda-blue-lt;
            }
            
        }
    }
}

@include respond-to-max('laptop') {
    .shared-nav {
         position: relative;
         z-index: 1001;
         .navbar-toggle {
             display: block;
         }
         .navigation-links {
            position: fixed;
            flex-direction: column;
            background-color: $nda-blue;
            width: 100vw;
            height: 100vh;
            top: 0;
            left: -100vw;
            justify-content: center;
            align-items: center;
            -moz-transition: left 0.5s ease;
            transition: left 0.5s ease;
            overflow-y: auto;
         }
         &.expanded {
            .top, .middle, .bottom {
                background-color: white !important;
            }
            .top {
                background-color: white !important;
                transform-origin: right;
                transform: rotate(-45deg);
            }
            .middle {
                opacity: 0;
            }
            .bottom {
                background-color: white !important;
                transform-origin: right;
                transform: rotate(45deg);
            }
            .navigation-links {
                left: 0; 
            }
        }
    }
}
