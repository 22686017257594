/* Base layout to replace bootstrap grids */

body, html{
  margin: 0;
}

.page-wrapper {
  // min-height: calc(100vh - 100px);
  background: $bg-gradient;
  .main-content {
    // display: flex;
    // margin: 2rem auto;
    // .content-wrapper {
    //   max-width: 1200px;
    //   &.full-bleed {
    //       width: 100%;
    //       max-width: unset;
    //   }
    // }
    // @include respond-to-min('tablet') {
    //   max-width: 800px;
    // }
  }
}

.two-column-group {
  display: flex;
  flex-wrap: wrap;
  & > div {
    flex: 0 0 50%;
    &:first-child {
      padding-right: 1.25rem;
    }
    &:last-child {
      padding-left: 1.25rem;
    }
  }
  @include respond-to-max('laptop') {
    & > div {
      flex: 0 0 100%;
      &:first-child {
        padding-right: 0;
      }
      &:last-child {
        padding-left: 0;
      }
    }
  }
}