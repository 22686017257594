@import "_colors";

.loader-alerts {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 100;
    .loader-errors {
        background: red;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        width: 100%;
        padding: .5rem 0;
        p {
            margin: 0;
            svg {
                margin-right: .5rem;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
    .loader-status {
        background: $ex-light-grey;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: .5rem 1rem;
        flex-direction: column;
        p {
            margin: 0;
            font-size: .8rem;
            padding: .25rem 0;
            svg {
                margin-right: .5rem;
            }
        }
    }
}
