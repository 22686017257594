/* Base form styling */
::placeholder {
    color: $medium-grey !important;
    font-weight: 300;
    font-style: italic;
    font-size: 1.5rem;
}


.DraftEditor-root {
    font-size: 1.4rem;
}

.form {
    display: flex;
    flex-wrap: wrap;
    .display-input {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        @include flexWidth(20, 25, 33.33, 50, 66.67, 75, 100);
        padding-right: .5rem;
    }
}

.form {
    .display-input {

        input,
        select,
        textarea {
            font-size: 1.5rem;
        }

        &.join-right {
            margin-right: 0px;
            padding-right: 0px;

            input {
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                border-right-color: transparent;
            }
        }

        &.join-left {
            margin-left: 0px;
            padding-left: 0px;
            @include dropDown;

            select {
                -webkit-border-top-left-radius: 0px;
                -webkit-border-bottom-left-radius: 0px;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                background-color: $light-grey;
            }

            input {
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
            }
        }

        .form-group {
            margin-bottom: 2.25rem;
            position: relative;

            label {
                font-size: 1.25rem;
                color: $dark-grey;
                // text-transform: uppercase;
            }
        }

        .invalid-feedback {
            text-align: left;
            padding-left: .5rem;
        }

        .calculated,
        .helper-text {
            color: #777;
            font-size: 1.2rem;
            padding: .33rem .25rem .5rem 0rem;
            position: absolute;
            left: 0;
            line-height: 1;
            text-align: left;
        }
    }

    &.horizontal {
        .display-input {
            flex-direction: row;

            .form-group {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                flex-wrap: wrap;

                label {
                    flex-basis: 20%;
                    font-size: 1.4rem;
                    text-transform: none;
                    font-weight: 400;
                    line-height: 34px;
                }

                .form-control {
                    border-color: $medium-grey;
                }

                input,
                select {
                    flex-basis: 80%;
                    font-size: 1.5rem;
                    height: 32px;
                }

                .invalid-feedback,
                .helper-text {
                    padding-top: .5rem;
                    margin-left: 20%;
                    width: 80%;
                    font-size: 1.2rem;
                    position: relative;
                }
            }
        }
    }

    &.inline {
        width: 100%;

        .display-input {
            flex: 1 1 auto;
        }

        .btn {
            &.icon-only {
                width: 4em;
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    }

    &.was-validated {
        .display-input {
            input[type="date"] {
                margin-right: -1em;
            }
        }
    }
}

.form-check-label {
    margin-left: 1rem;
}

.file-drop-area {
    margin: 2rem 0 0 0 !important;
    align-items: flex-start !important;

    label {
        display: block;
    }

    &>div {
        flex: 1 1 auto;
        display: flex;
    }

    input {
        display: none;
        /* NOTE: TODO: Show this on mobile probably */
    }

    .helper-text {
        margin-left: 0 !important;
    }

    .dragarea-wrapper {
        display: block;
        position: relative;
        width: 100%;
        max-width: 500px;
        min-height: 10rem;
        text-align: center;
        cursor: pointer;

        .dragarea-mssg {
            padding: 1em 2em;
            border: dashed grey thin;
        }

        .dragarea-target {
            border: dashed grey thin;
            background-color: rgba(200, 200, 200, .8);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 9999;
        }

        .dragarea-label {
            position: absolute;
            top: 50%;
            right: 0;
            left: 0;
            text-align: center;
            color: grey;
        }
    }

    .alert {
        margin-bottom: 0;
    }
}

.inset-form {
    background: white;
    padding: 1rem 1.5rem;
    border-radius: 4px;
}

.react-datepicker {
    font-family: inherit;
    font-weight: 400;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;

    .react-datepicker__month-container {
        float: left;
        width: 100%;
        .react-datepicker__header {
            text-align: center;
            background-color: #f0f0f0;
            border-bottom: 1px solid #aeaeae;
            border-top-left-radius: 0.3rem;
            padding: 8px 0;
            position: relative;
        }
        .react-datepicker__day--disabled {
            color: #BBB !important;
        }
        .react-datepicker__day--selected{
            border-radius: 0.3rem;
            background-color: $nda-blue-50;
            color: #fff;
        }
        .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
            color: #000;
            display: inline-block;
            width: 1.7rem;
            line-height: 1.7rem;
            text-align: center;
            margin: 0.8rem;
        }
    }
    .react-datepicker__time-container {
        float: right;
        border-left: 1px solid #aeaeae;
        width: 100%;
        .react-datepicker__time-box {
            overflow-x: hidden;
            margin: 0 auto;
            text-align: center;
            border-bottom-right-radius: 0.3rem;
            width: 100%;
        }
    }
}