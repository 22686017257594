/* Colors and color themes */

$nda-blue: #1a4fb0;
$nda-blue-lt: #97C7FF;
$nda-blue-md: #3C71BD;
$nda-blue-shaded: #D2E0F5;
$nda-blue-50: rgba(25, 79, 175, .5);
$nda-alpha-blue: rgba(25, 79, 181, .2);
$nda-alpha-red: rgba(241, 86, 78, .2);

$dark-grey: rgb(77, 77, 77);
$medium-grey: rgb(173, 173, 173);
$light-grey: rgb(232, 232, 232);
$ex-light-grey: #f7f7f7;

$stoplight-red: rgb(247, 85, 75);
$stoplight-light-red: rgb(255, 230, 229);
$stoplight-purple: rgb(140, 73, 145);
$stoplight-light-purple: rgb(238, 228, 238);
$stoplight-blue: rgb(29, 82, 178);
$stoplight-light-blue: rgb(221, 230, 244);

$stoplight-black: rgb(51, 51, 51);
$stoplight-medium-grey: rgb(146, 146, 146);
$stoplight-light-grey: rgb(242, 242, 242);

$bg-gradient: linear-gradient(to right, #F5F8FB, #cddced);